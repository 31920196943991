```vue
<template>
  <div class="article-page">
    <div v-if="loading">Loading article...</div>
    <div v-else-if="error">{{ error }}</div>
    <article v-else-if="article" class="article-content">
      <div v-if="article.Category" class="article-category">{{ article.Category }}</div>
      <h1>{{ article.Title }}</h1>
      <p v-if="article.PublishDate" class="article-meta">
        Published on {{ article.PublishDate }}
      </p>
      
      <div class="article-body" v-html="processedContent"></div>
    </article>
    <div v-else>No article found</div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue'; // Added watch
import { useRoute } from 'vue-router';
import axios from 'axios';
import { useHead } from '@vueuse/head';
import { generateMetaTags } from '../meta';

export default {
  name: 'ArticlePage',
  setup() {
    const route = useRoute();
    const article = ref(null);
    const loading = ref(true);
    const error = ref(null);

    // Add watch for SEO meta tags
    watch(() => article.value, (newArticle) => {
      if (newArticle) {
        // Get clean description from RichText (remove markdown)
        const description = newArticle.RichText
          .replace(/!\[.*?\]\(.*?\)/g, '') // Remove images
          .replace(/\*\*/g, '')            // Remove bold markers
          .replace(/#{1,3} /g, '')         // Remove headers
          .replace(/Drag/g, '')            // Remove 'Drag'
          .split('\n')[0]                  // Get first paragraph
          .slice(0, 160);                  // Limit length for meta description

        useHead(generateMetaTags({
          title: `${newArticle.Title} - Top5`,
          description: description,
          url: `https://yourdomain.com/article/${newArticle.documentId}`,
          // Add article's first image as OG image if exists
          image: newArticle.RichText.match(/!\[.*?\]\((.*?)\)/)?.[1] || '/logo.png'
        }));
      }
    });
 

    const processedContent = computed(() => {
      if (!article.value?.RichText) return '';

      let content = article.value.RichText;
      
      // Process markdown
      content = content
        // Handle headings with hash
        .replace(/^# (.*$)/gm, '<h1>$1</h1>')
        .replace(/^## (.*$)/gm, '<h2>$1</h2>')
        .replace(/^### (.*$)/gm, '<h3>$1</h3>')
        
        // Handle bold text
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
        
        // Handle images
        .replace(/!\[(.*?)\]\((.*?)\)/g, '<img src="$2" alt="$1" class="content-image">')
        
        // Handle line breaks while removing "Drag"
        .replace(/Drag/g, '')
        .replace(/\n{2,}/g, '</p><p>')
        .replace(/\n/g, '<br>')
        
        // Handle paragraphs
        .replace(/---/g, '<hr>')
        
        // Wrap in paragraphs if not already wrapped
        .trim();

      if (!content.startsWith('<')) {
        content = '<p>' + content;
      }
      if (!content.endsWith('>')) {
        content = content + '</p>';
      }

      return content;
    });

    const fetchArticle = async (documentId) => {
      loading.value = true;
      error.value = null;
      try {
        const response = await axios.get(`http://localhost:1337/api/articles?filters[documentId]=${documentId}`);
        if (response.data.data && response.data.data.length > 0) {
          article.value = response.data.data[0];
          console.log('Fetched article:', article.value);
        } else {
          throw new Error('Article not found');
        }
      } catch (err) {
        console.error('Error fetching article:', err);
        error.value = 'Failed to load article. Please try again later.';
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      const documentId = route.params.id;
      if (documentId) {
        fetchArticle(documentId);
      }
    });

    return {
      article,
      loading,
      error,
      processedContent
    };
  }
};
</script>

<style scoped>

.back-button {
  display: inline-flex;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
  padding: 10px 20px;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  transition: all 0.3s ease;
  font-weight: 500;
}

.back-button:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateX(-5px);
}

.back-icon {
  margin-right: 8px;
  font-size: 20px;
}

.article-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  color: #ffffff;
}

.article-category {
  display: inline-block;
  background: #2ecc71;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 20px;
}

h1 {
  font-size: 32px;
  margin-bottom: 10px;
  color: #ffffff;
}

.article-meta {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  margin-bottom: 30px;
}

:deep(.article-body) {
  line-height: 1.6;
  
  h1, h2, h3 {
    margin: 1.5em 0 0.5em;
    color: #ffffff;
  }

  h1 { font-size: 28px; }
  h2 { font-size: 24px; }
  h3 { font-size: 20px; }

  p {
    margin-bottom: 1em;
  }

  strong {
    font-weight: 600;
  }

  .content-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    margin: 1.5em 0;
  }

  hr {
    border: none;
    border-top: 2px solid rgba(255, 255, 255, 0.1);
    margin: 2em 0;
  }
}
</style>
<template>
  <router-link to="/" class="logo-link">
    <div class="logo-container" ref="logoContainer">
      <div class="logo">
        <span class="logo-text">Top<span class="logo-number">5</span></span>
        <span class="dynamic-text">{{ currentTopic }}</span>
      </div>
    </div>
  </router-link>
</template>

<script>
import { ref, onMounted, onUnmounted, watch } from 'vue'

export default {
  name: 'DynamicLogo',
  props: {
    isPaused: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const topics = ['Books', 'Movies', 'Games', 'Apps', 'IT', 'Places', 'Foods', 'Cars', 'Tech', 'Travel', 'Trends']
    const currentTopic = ref(topics[0])
    const logoContainer = ref(null)
    let interval = null

    const changeTopic = () => {
      const currentIndex = topics.indexOf(currentTopic.value)
      const nextIndex = (currentIndex + 1) % topics.length
      currentTopic.value = topics[nextIndex]
    }

    const adjustContainerWidth = () => {
      if (logoContainer.value) {
        logoContainer.value.style.width = 'auto'
        const width = logoContainer.value.offsetWidth
        logoContainer.value.style.width = `${width}px`
      }
    }

    // Watch for pause state changes
    watch(() => props.isPaused, (newValue) => {
      if (newValue) {
        // Clear interval if paused
        if (interval) {
          clearInterval(interval)
          interval = null
        }
      } else {
        // Restart interval if unpaused
        if (!interval) {
          interval = setInterval(changeTopic, 2000)
        }
      }
    })

    watch(currentTopic, () => {
      setTimeout(adjustContainerWidth, 0)
    })

    onMounted(() => {
      if (!props.isPaused) {
        interval = setInterval(changeTopic, 2000)
      }
      adjustContainerWidth()
    })

    onUnmounted(() => {
      if (interval) {
        clearInterval(interval)
      }
    })

    return {
      currentTopic,
      logoContainer
    }
  }
}
</script>

<style scoped>
.logo-container {
  display: inline-block;
  background: rgba(255, 255, 255, 0.2);
  padding: 1px 1px;
  border-radius: 10px;
  transition: width 0.3s ease;
}

.logo {
  display: flex;
  align-items: center;
}

.logo-text {
  font-size: 32px;
  font-weight: 700;
  color: #ffffff;
}

.logo-number {
  color: #2ecc71;
}

.dynamic-text {
  font-size: 24px;
  margin-left: 10px;
  color: #ffffff;
  font-weight: 600;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}
</style>
<template>
  <div id="app">
    <div class="background-animation"></div>
    <header>
    <div class="header-content">
      <DynamicLogo :isPaused="isBackgroundStatic" />
      <button @click="toggleBackgroundAnimation" class="bg-toggle" :title="isBackgroundStatic ? 'Enable animation' : 'Disable animation'">
        {{ isBackgroundStatic ? '▶' : '⏸' }}
      </button>
    </div>
    </header>

    <router-view></router-view>


  </div>
</template>

<script>
import { ref } from 'vue';
import DynamicLogo from '@/components/DynamicLogo.vue';

export default {
  name: 'App',
  components: {
    DynamicLogo
  },
  setup() {
    const isBackgroundStatic = ref(false);

    const toggleBackgroundAnimation = () => {
      isBackgroundStatic.value = !isBackgroundStatic.value;
      document.querySelector('.background-animation').style.animationPlayState = 
        isBackgroundStatic.value ? 'paused' : 'running';
    };

    return {
      isBackgroundStatic,
      toggleBackgroundAnimation
    };
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Poppins', sans-serif;
}

.home {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  position: relative;
  overflow: hidden;
}

.background-animation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  z-index: -1;
}

@keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

header {
  text-align: center;
  padding: 20px 0;
}

.header-content {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.bg-toggle {
  background: rgba(255, 255, 255, 0.2);
  border: none;
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.bg-toggle:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.logo {
  display: inline-block;
  background: rgba(255, 255, 255, 0.1);
  padding: 10px 20px;
  border-radius: 10px;
}

.logo-text {
  font-size: 32px;
  font-weight: 700;
}

.logo-number {
  color: #2ecc71;
}

main {
  flex-grow: 1;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

.search-container {
  margin-bottom: 20px;
}

.search-input {
  width: 100%;
  padding: 15px 20px;
  font-size: 18px;
  border: none;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.2);
  color: #ffffff;
  transition: all 0.3s ease;
}

.search-input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.search-input:focus {
  outline: none;
  background: rgba(255, 255, 255, 0.3);
}

.suggestion-cards {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.suggestion-card {
  background: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.suggestion-card:hover {
  background: rgba(255, 255, 255, 0.3);
}

.articles-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.article-card {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 20px;
  transition: all 0.3s ease;
  position: relative;
  backdrop-filter: blur(10px);
}

.article-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.article-category {
  position: absolute;
  top: 15px;
  right: 15px;
  background: #2ecc71;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
  font-weight: bold;
}

.article-card h3 {
  font-size: 22px;
  margin-bottom: 10px;
}

.article-card p {
  font-size: 16px;
  margin-bottom: 15px;
  color: rgba(255, 255, 255, 0.8);
}

.read-more {
  color: #2ecc71;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.2s ease;
}

.read-more:hover {
  color: #ffffff;
}

footer {
  text-align: center;
  padding: 20px 0;
  font-size: 14px;
  background: rgba(0, 0, 0, 0.2);
}
</style>
```vue
<template>
  <main>
    <div class="search-container">
      <input
        v-model="searchQuery"
        @input="searchArticles"
        type="text"
        placeholder="Search for top 5 lists..."
        class="search-input"
      />
    </div>

    <div class="suggestion-cards">
      <button 
        v-for="nav in navItems" 
        :key="nav.tag"
        :class="['suggestion-card', { active: currentTag === nav.tag }]"
        @click="filterByTag(nav.tag)"
      >
        {{ nav.name }}
      </button>
    </div>

    <div v-if="loading" class="loading">Loading articles...</div>
    <div v-else-if="error" class="error">{{ error }}</div>
    <div v-else class="articles-container">
      <template v-if="filteredArticles.length > 0">
        <div v-for="article in filteredArticles" :key="article.id" class="article-card">
          <span v-if="article.Category" class="article-category">
            {{ article.Category }}
          </span>
          <h3>{{ article.Title }}</h3>
          <!-- Use processed markdown for preview -->
          <div 
            class="article-preview" 
            v-html="getProcessedPreview(article.RichText)"
          ></div>
          <router-link 
            :to="{ name: 'Article', params: { id: article.documentId }}" 
            class="read-more"
          >
            Read more
          </router-link>
        </div>
      </template>
      <div v-else class="no-articles">
        No articles found
      </div>
    </div>
  </main>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';
import { useHead } from '@vueuse/head'; // Install this package
import { generateMetaTags } from '../meta';


export default {
  name: 'HomePage',
  setup() {
    const searchQuery = ref('');
    const articles = ref([]);
    const loading = ref(true);
    const error = ref(null);
    const currentTag = ref('');

    const navItems = [
      { name: 'Trending', tag: 'trending' },
      { name: 'Popular', tag: 'popular' },
      { name: 'Recent', tag: 'recent' },
      { name: 'Random', tag: 'random' },
    ];

const filteredArticles = computed(() => {
  if (!articles.value) return [];
  
  let filtered = articles.value;

  if (currentTag.value) {
    filtered = filtered.filter(article => 
      article.Tag1 === currentTag.value ||
      article.Tag2 === currentTag.value ||
      article.Tag3 === currentTag.value ||
      article.Tag4 === currentTag.value
    );
  }

  if (searchQuery.value) {
    const query = searchQuery.value.toLowerCase();
    filtered = filtered.filter(article =>
      (article.Title?.toLowerCase().includes(query)) ||
      (article.RichText?.toLowerCase().includes(query)) ||
      (article.Category?.toLowerCase().includes(query))
    );
  }

  return filtered;
});


    // Add meta tags
    useHead(generateMetaTags({
      title: 'Top5 - Discover Best Curated Lists',
      description: 'Explore expert-curated top 5 lists in technology, travel, lifestyle, and more. Get insights and recommendations from our comprehensive rankings.'
    }));
	
    const filterByTag = (tag) => {
      currentTag.value = currentTag.value === tag ? '' : tag;
      console.log('Current tag set to:', currentTag.value);
    };

    const truncateContent = (content) => {
      if (!content) return '';
      const plainText = content
        .replace(/!\[.*?\]\(.*?\)/g, '')
        .replace(/\n/g, ' ')
        .replace(/\s+/g, ' ')
        .replace(/Drag/g, '')
        .trim();
      return plainText.length > 150 ? plainText.slice(0, 150) + '...' : plainText;
    };

const fetchArticles = async () => {
  loading.value = true;
  error.value = null;
  try {
    // Use process.env instead of import.meta.env
    const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/articles`);
    if (response.data && response.data.data) {
      articles.value = response.data.data;
      console.log('Fetched articles:', articles.value);
    }
  } catch (err) {
    console.error('Error fetching articles:', err);
    error.value = err.message || 'Failed to load articles. Please try again.';
  } finally {
    loading.value = false;
  }
};

    onMounted(() => {
      fetchArticles();
    });

    const processMarkdown = (text) => {
      if (!text) return '';
      
      return text
        // Handle headings
        .replace(/^# (.*$)/gm, '<strong>$1</strong>')
        .replace(/^## (.*$)/gm, '<strong>$1</strong>')
        
        // Handle bold text
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
        
        // Remove image markdown
        .replace(/!\[.*?\]\(.*?\)/g, '')
        
        // Clean up extra whitespace and "Drag" text
        .replace(/Drag/g, '')
        .replace(/\n{2,}/g, ' ')
        .replace(/\n/g, ' ')
        .trim();
    };

    const getProcessedPreview = (text) => {
      if (!text) return '';
      
      // Process markdown first
      let processed = processMarkdown(text);
      
      // Extract first meaningful paragraph
      let firstParagraph = processed.split(/[.!?]/).filter(s => s.trim().length > 50)[0];
      
      // Limit length and add ellipsis if needed
      if (firstParagraph && firstParagraph.length > 200) {
        firstParagraph = firstParagraph.slice(0, 200).trim() + '...';
      }
      
      return `<p>${firstParagraph}</p>`;
    };

    return {
      searchQuery,
      filteredArticles,
      navItems,
      currentTag,
      filterByTag,
      truncateContent,
      loading,
      error,
	getProcessedPreview
    };
  }
};
</script>

<style scoped>
.suggestion-cards {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 30px;
}

.suggestion-card {
  background: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  flex: 0 1 auto;
  min-width: 100px;
}

.suggestion-card:hover {
  background: rgba(255, 255, 255, 0.3);
  transform: translateY(-2px);
}

.suggestion-card.active {
  background: #2ecc71;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(46, 204, 113, 0.2);
}

.suggestion-card.active:hover {
  background: #27ae60;
}

@media (max-width: 768px) {
  .suggestion-cards {
    gap: 8px;
  }

  .suggestion-card {
    padding: 8px 16px;
    font-size: 12px;
    min-width: 80px;
  }
}

@media (max-width: 480px) {
  .suggestion-cards {
    justify-content: space-between;
    gap: 6px;
  }

  .suggestion-card {
    flex: 1 1 calc(50% - 6px);
    margin-bottom: 6px;
  }
}

.article-card {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 20px;
  backdrop-filter: blur(10px);
}

.article-preview {
  margin: 10px 0;
  color: rgba(255, 255, 255, 0.9);
}

:deep(.article-preview) {
  p {
    margin: 0;
    line-height: 1.6;
  }

  strong {
    font-weight: 600;
    color: #ffffff;
  }
}

.article-category {
  display: inline-block;
  background: #2ecc71;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px;
}

h3 {
  font-size: 24px;
  margin: 10px 0;
  color: #ffffff;
}

.read-more {
  display: inline-block;
  color: #2ecc71;
  text-decoration: none;
  font-weight: 600;
  margin-top: 10px;
  transition: color 0.3s ease;
}

.read-more:hover {
  color: #ffffff;
}

/* Enhance responsive design */
/* Mobile specific adjustments */
@media (max-width: 768px) {
  .search-container {
    width: 90%; /* Slightly wider on mobile */
  }

  .suggestion-cards {
    gap: 8px;
  }

  .suggestion-card {
    flex: 0 0 calc(50% - 4px); /* Two columns on mobile */
    font-size: 14px;
    min-height: 40px;
  }

  .article-card {
    margin: 10px;
    padding: 15px;
  }

  .article-card h3 {
    font-size: 18px; /* Smaller headings on mobile */
  }

  .article-card p {
    font-size: 14px; /* Smaller paragraph text on mobile */
    line-height: 1.4;
  }
}


</style>
